import React from "react";
import { useState } from "react";
import Assets from "../Layout/CommonLayout/Asset";
import Style from "./NewCategorySlider.module.scss";
import Slider from "react-slick";

function NewCategorySlider() {
    const [newcategoryList,setNewList] = useState([{image:Assets.category_08,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_09,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_10,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_11,itemTitle:"Orci varius natoque penatibus",price:2}]);
    const settings = {
        
        infinite: false,
        arrows: true,
        speed: 800,
        slidesToShow: 4,
        /* prevArrow: $('.cprev'),
        nextArrow: $('.cnext'), */
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3
            }

        }, {

        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }

        }, {

            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                rows: 1
            }

        }]
    };

  return (

        <Slider {...settings}  className={Style.category_slider}>
                {newcategoryList.map((items)=>{
                    return(
                    <div className={Style.slidecols}>
                    <div className={Style.slide_item}>
                        <figure className={Style.imgsec}><img src={items.image} alt=""/></figure>
                        <div className={Style.detail_sec}>
                            <header className={Style.itemtitle}>{items.itemTitle}</header>
                            <div className={Style.price}>${items.price}</div>
                            <div className={Style.dwnld_icon}><a href="#"><img src={Assets.direct_download} alt=""/></a></div>
                        </div>
                    </div>
                    </div>
                    )
                })}
                
                {newcategoryList.map((items)=>{
                    return(
                    <div className={Style.slidecols}>
                    <div className={Style.slide_item}>
                        <figure className={Style.imgsec}><img src={items.image} alt=""/></figure>
                        <div className={Style.detail_sec}>
                            <header className={Style.itemtitle}>{items.itemTitle}</header>
                            <div className={Style.price}>${items.price}</div>
                            <div className={Style.dwnld_icon}><a href="#"><img src={Assets.direct_download} alt=""/></a></div>
                        </div>
                    </div>
                    </div>
                    )
                })}
            </Slider>

);
}

export default NewCategorySlider;