import React, { useState } from "react";
import CardDetails from "../CardDetails";
import Assets from "../Layout/CommonLayout/Asset";
import Payment from "../Payment";
import Style from "./ChatInputs.module.scss";

function ChatInputs({cancelChat}) {

    const [showModal, setModal] = useState(false);

    const setModel = () =>{
      setModal(true);
    }
   
    const hidemodal = () => {
      setModal(false);
    };

  return (


    <div className={Style.chat_wrapper}>

    <div className={Style.chat_input_wrapper}>
        <div className={Style.chat_input}>
            <input type="text"/>
            
        </div>
        <button   bsPrefix="custom" className={Style.attachment_btn}>
            <input type="file"/>
            <img src={Assets.attachment} alt=""/>
        </button>
    </div>
    <div className={Style.chat_action}>
        <button   bsPrefix="custom" className={Style.btn_cancel} onClick={cancelChat}>
            Cancel
        </button>
        <button   bsPrefix="custom" className={Style.btn_submit} 
        // onClick={()=>setModel()} 
        >
            Submit
        </button>
    </div>


    <div className={Style.chat_section}>
        <ul>
            <li className={Style.user}>
                <div className={Style.user_chat}>
                    <div className={Style.user_image}>
                        j
                    </div>
                    <div className={Style.user_text}>
                        <span className={Style.time}>
                            18:00
                        </span>
                        <span className={Style.text}>Like This ?</span>
                    </div>
                </div>
            </li>
            <li className={Style.bot}>
                <div className={Style.bot_chat}>
                    <div className={Style.bot_image}>
                        j
                    </div>
                    <div className={Style.bot_text}>
                        <span className={Style.time}>
                            18:00
                        </span>
                        <span className={Style.text}>Like This ?</span>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <Payment
        show={showModal}
        handleClose={hidemodal}
      />
</div>

);
}

export default ChatInputs;