import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import Style from "./LoginPopup.module.scss";
import UserLogin from "../UserLogin";
import { LoginSignupContext } from "../../context/loginSignupModal";

function LoginPopup() {
  // State for Login/Signup Modal
  const {
    loginSignupState: { isLogin },
    dispatchLoginSignup,
  } = useContext(LoginSignupContext);

  return (
    <>
      <Modal
        show={isLogin}
        dialogClassName="login_modal custom_styling"
        centered
      >
        <Modal.Header
          onClick={() => dispatchLoginSignup({ type: "CLOSE_LOGIN_SIGNUP" })}
        >
          <div className={Style.modal_header}>x</div>
        </Modal.Header>
        <Modal.Body>
          <UserLogin />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default LoginPopup;
