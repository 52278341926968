import React from "react";
import Map from "../components/Map";
import Header from "../components/Header";

function MapPage() {
  return (
    <>
      <Header />
      <Map />
    </>
  );
}

export default MapPage;
