import React from "react";
import MainPage from "../components/mainpage";
import Header from "../components/Header";
import Footer from "../components/Footer";


function MainPageView() {
  return (
    <>
    <Header/>
      <MainPage />
    <Footer/>  
    </>
  );
}

export default MainPageView;
