
import React,{useState} from "react";
import Style from "./UseCaseOne.module.scss";
import Assets from "../Layout/CommonLayout/Asset";
import ReactPlayer from "react-player";

function UseCaseOne() {

    const [images,setImages] = useState([{image:Assets.usecasedetail01},{image:Assets.usecasedetail02}]);
    const [relatedImages,setRelatedImages] = useState([{image:Assets.usecasedetail03},{image:Assets.usecasedetail04},{image:Assets.usecasedetail05}]) 
    const [volume,setVolume] = useState(100)
    const [play,setPlay] = useState(false)
    return (
        <>
        
        <section className="slimbnr">
            <div className="container">
                <header className="pagetitle"><h1>Use Case One</h1></header>
            </div>
        </section> 

        <section className={Style.pagecntsec}>
            <div className="container">
                <div className={Style.secwrapper}>
                    <div className={Style.titlewrap}><h2 className={Style.sectitle}>Orci varius natoque penatibus et magnis dis</h2></div>
                    <p>Quisque aliquam rutrum eros consequat condimentum. In vitae diam diam. Suspendisse potenti. Ut tortor nunc, euismod a fringilla at, hendrerit sit amet purus. Donec sit amet dolor quis ex mattis ornare nec ut est. Vivamus ele</p>
                    <p>Donec vitae tempus risus. Pellentesque vel facilisis enim, quis iaculis tortor. Aenean eget lectus odio. Aliquam vulputate iaculis mattis. Phasellus consectetur posuere nisl, nec malesuada magna. Nam malesuada vel diam ut vestibulum. In cursus, odio at bibendum condimentum, nunc urna feugiat nisi, nec luctus lectus dolor sed enim. Inteaugue felis, luctus sit amet pretium sed, scelerisque vitae elit. Maecenas lacinia nunc a leo dignissim, ac suscipit magna malesuada. Morbi malesuada enim non arcu ullamcorper, ac blandit magna eleifend. Aliquam fermentum ornare enim nec imperdiet. Sed convallis egestas tellus eget lobortis. </p>

                    <div className={Style.videoimgs}>
                        <div className={Style.videowrapper}>
                            <div className={Style.usecase_videosec}>
                            <ReactPlayer
                                url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                                volume={volume}
                                width={"100%"}
                                playing={true}
                                height={"100%"}
                                controls={true}
                                pip={true}
                                light={Assets.videoposter}
                                playIcon= {<div className={Style.videoposter}><button className={Style.paybtn}></button></div>}
                                className={Style.video} 
                                /> 
                                {/* <video className={Style.video}>
                                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                                </video> */}
                                {/* <figure className={Style.videoposter playpause}><img src="assets/images/use-cases/detail/use_videoposter.jpg" alt=""/> <button className={Style.paybtn"></button></figure> */}
                            </div>
                        </div>
                        <div className={Style.imgswrapper}>
                        {images.map((image)=>{
                            return(
                                <figure className={Style.imgsec}>
                                <img src={image.image} alt=""/>
                                </figure>
                            )
                        })}
                        </div>
                    </div>

                    <p>Quisque aliquam rutrum eros consequat condimentum. In vitae diam diam. Suspendisse potenti. Ut tortor nunc, euismod a fringilla at, hendrerit sit amet purus. Donec sit amet dolor quis ex mattis ornare nec ut est. Vivamus eleifend dolor ante. Duis nulla tortor, venenatis non congue in, porta et leo. Aenean scelerisque felis et dolor luctus blandit.</p>
                    <p>Donec vitae tempus risus. Pellentesque vel facilisis enim, quis iaculis tortor. Aenean eget lectus odio. Aliquam vulputate iaculis mattis. Phasellus consectetur posuere nisl, nec malesuada magna. Nam malesuada vel diam ut vestibulum. In cursus, odio at bibendum condimentum, nunc urna feugiat nisi, nec luctus lectus dolor sed enim.</p>
                    <p>Integer augue felis, luctus sit amet pretium sed, scelerisque vitae elit. Maecenas lacinia nunc a leo dignissim, ac suscipit magna malesuada. Morbi malesuada enim non arcu ullamcorper, ac blandit magna eleifend. Aliquam fermentum ornare enim nec imperdiet. Sed convallis egestas tellus eget lobortis.</p>

                    
                    <div className={Style.relatedimgs}>
                        {relatedImages.map((image)=>{
                            return(
                                <figure className={Style.imgsec}>
                                <img src={image.image} alt=""/>
                                </figure>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>


        </>
    );
}

export default UseCaseOne;