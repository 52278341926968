import React from "react";
import UserLogin from "../components/UserLogin";


function UserLoginPage() {
  return (
    <UserLogin/>
  );
}

export default UserLoginPage;
