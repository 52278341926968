import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import Style from "./BillBoardsSearch.module.scss";
//import PaymentSlider from "../PaymentSlider";
import SearchInput from "../SearchInput";
import { useHistory } from "react-router-dom";
//import Assets from "../Layout/CommonLayout/Asset";
import BillBoards from "../BillBoards";
import { loadBillBoards } from "../../api/userApi";
import Skeleton from "@mui/material/Skeleton";
import { Context } from "../../context";
import axios from "axios";

function BillBoardsSearch() {
    // context state
    const {
        state: { user },
    } = useContext(Context);

    const [isScrollUp, setIsScrollUp] = useState(false);
    const [clientIp, setClientIp] = useState("");

    const history = useHistory();

    const [boardsList, setBoardsList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user !== null) {
            loadBillBoards(
                setBoardsList,
                setLoading,
                user?.email,
                user?.token,
                user?.tokenType,
                user?.client,
                user?.exp
            );
        }
    }, [user]);

    const fetchUserIP = useCallback(async () => {
        const { data } = await axios.get("https://geolocation-db.com/json/");
        const clientIP = data["IPv4"];
        setClientIp(clientIP);
    }, []);

    useEffect(() => {
        fetchUserIP();
    }, [fetchUserIP]);

    const scrollRef = useRef();

    const scroll = (scrollOffset) => {
        // If scroll down , it shows up arrow
        if (scrollOffset === -133) setIsScrollUp(true);
        scrollRef.current.scrollTop -= scrollOffset;
        // If it goes fully up, it hides up arrow
        if (scrollRef.current.scrollTop === 0) setIsScrollUp(false);
    };

    const showLoading = () => (
        <>
            <div className={Style.result_item_wrapper}>
                <div className={Style.result_item_inner}>
                    <div className={Style.collage_wrapper}>
                        <div className={Style.imgsec}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <h3>
                        <Skeleton variant="text" />
                    </h3>
                    <p>
                        <Skeleton variant="text" />
                    </p>
                </div>
            </div>
            <div className={Style.result_item_wrapper}>
                <div className={Style.result_item_inner}>
                    <div className={Style.collage_wrapper}>
                        <div className={Style.imgsec}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <h3>
                        <Skeleton variant="text" />
                    </h3>
                    <p>
                        <Skeleton variant="text" />
                    </p>
                </div>
            </div>
            <div className={Style.result_item_wrapper}>
                <div className={Style.result_item_inner}>
                    <div className={Style.collage_wrapper}>
                        <div className={Style.imgsec}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <h3>
                        <Skeleton variant="text" />
                    </h3>
                    <p>
                        <Skeleton variant="text" />
                    </p>
                </div>
            </div>
            <div className={Style.result_item_wrapper}>
                <div className={Style.result_item_inner}>
                    <div className={Style.collage_wrapper}>
                        <div className={Style.imgsec}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <h3>
                        <Skeleton variant="text" />
                    </h3>
                    <p>
                        <Skeleton variant="text" />
                    </p>
                </div>
            </div>
            <div className={Style.result_item_wrapper}>
                <div className={Style.result_item_inner}>
                    <div className={Style.collage_wrapper}>
                        <div className={Style.imgsec}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <h3>
                        <Skeleton variant="text" />
                    </h3>
                    <p>
                        <Skeleton variant="text" />
                    </p>
                </div>
            </div>
        </>
    );

    const onScrollBoard = (e) => {
        if (e.target.scrollTop) {
            setIsScrollUp(true);
        } else {
            setIsScrollUp(false);
        }
    };

    return (
        <BillBoards>
            <div className={Style.search_billboard_wrapper}>
                <div className={Style.search_billboard_inner}>
                    <SearchInput />

                    <div
                        onScroll={onScrollBoard}
                        id="search__bill__scroll"
                        className={Style.search_billboard_result}
                        ref={scrollRef}
                    >
                        {!loading && boardsList.length <= 0 && (
                            <div className={Style.no__results}>
                                <h3>No Results Found!</h3>
                            </div>
                        )}
                        <div className={Style.search_billboard_result_inner}>
                            {/* show Loading */}
                            {loading && showLoading()}

                            {!loading &&
                                boardsList.length >= 1 &&
                                boardsList.map((board) => {
                                    return (
                                        <div
                                            key={`${board.event_type}_${
                                                board.event_type_id ||
                                                new Date().getUTCMilliseconds()
                                            }`}
                                            className={
                                                Style.result_item_wrapper
                                            }
                                        >
                                            <div
                                                className={
                                                    Style.result_item_inner
                                                }
                                            >
                                                <div
                                                    className={
                                                        Style.collage_wrapper
                                                    }
                                                >
                                                    <div
                                                        className={Style.imgsec}
                                                    >
                                                        <div
                                                            className={
                                                                Style.big_image
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    Style.image_wrapper
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        Style.image_container
                                                                    }
                                                                >
                                                                    <img
                                                                        onClick={() =>
                                                                            history.push(
                                                                                {
                                                                                    pathname:
                                                                                        "/billboards",
                                                                                    search: `?id=${board?.event_type_id}`,
                                                                                },
                                                                                {
                                                                                    event_name:
                                                                                        board?.event_type,
                                                                                }
                                                                            )
                                                                        }
                                                                        src={
                                                                            board
                                                                                ?.image_urls[0]
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                Style.small_image
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    Style.image_wrapper
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        Style.image_container
                                                                    }
                                                                >
                                                                    <img
                                                                        onClick={() =>
                                                                            history.push(
                                                                                {
                                                                                    pathname:
                                                                                        "/billboards",
                                                                                    search: `?id=${board?.event_type_id}`,
                                                                                },
                                                                                {
                                                                                    event_name:
                                                                                        board?.event_type,
                                                                                }
                                                                            )
                                                                        }
                                                                        src={
                                                                            board
                                                                                ?.image_urls[1]
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    Style.image_wrapper
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        Style.image_container
                                                                    }
                                                                >
                                                                    <img
                                                                        onClick={() =>
                                                                            history.push(
                                                                                {
                                                                                    pathname:
                                                                                        "/billboards",
                                                                                    search: `?id=${board?.event_type_id}`,
                                                                                },
                                                                                {
                                                                                    event_name:
                                                                                        board?.event_type,
                                                                                }
                                                                            )
                                                                        }
                                                                        src={
                                                                            board
                                                                                ?.image_urls[2]
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*  <span className={Style.tag_red}>Cold</span> */}
                                                {/* {board.tag == "cold" ? (
                      <span className={Style.tag_red}>Cold</span>
                  ) : (
                      <span className={Style.tag_green}>Hot</span>
                  )} */}
                                                <h3
                                                    onClick={() =>
                                                        history.push(
                                                            {
                                                                pathname:
                                                                    "/billboards",
                                                                search: `?id=${board?.event_type_id}`,
                                                            },
                                                            {
                                                                event_name:
                                                                    board?.event_type,
                                                            }
                                                        )
                                                    }
                                                >
                                                    {board.event_type}
                                                </h3>
                                                <p
                                                    onClick={() =>
                                                        history.push(
                                                            {
                                                                pathname:
                                                                    "/billboards",
                                                                search: `?id=${board?.event_type_id}`,
                                                            },
                                                            {
                                                                event_name:
                                                                    board?.event_type,
                                                            }
                                                        )
                                                    }
                                                >
                                                    {board.event_count} Results
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className={Style.read_more_wrapper_extra}>
                    {boardsList.length >= 1 && (
                        <>
                            <button
                                className={Style.read_more_btn}
                                onClick={() => scroll(-133)}
                            ></button>
                            {isScrollUp && (
                                <button
                                    className="icon__rotate"
                                    onClick={() => scroll(133)}
                                ></button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </BillBoards>
    );
}

export default BillBoardsSearch;
