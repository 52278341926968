import React, { useState } from "react";
import Assets from "../Layout/CommonLayout/Asset";
import { useHistory } from "react-router-dom";
import Style from "./MainPage.module.scss";
import VideoSearch from "../VideoSearch";
import { loadAutoSearch } from "../../api/commonApi";

function MainPage() {
  const [dropdownList, setDropdown] = useState([]);
  const [search, setSearch] = useState("");
  const [showCard, setCard] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);
  const history = useHistory();
  const [listLoading, setListLoading] = useState(false);

  const hideCard = () => {
    setCard(false);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    // For Passing empty value
    if (value.length === 0) {
      setItemSelected(true);
      return;
    }
    //trim will remove white spaces.
    if (value.trim().length === 0) {
      setItemSelected(true);
      return;
    }
    setItemSelected(false);
    // Else Load Auto Search
    loadAutoSearch(value, setDropdown, setListLoading);
  };

  const sendSearch = () => {
    localStorage.setItem("flow", "searchdesktop");
    localStorage.setItem("modalClosed", false);
    sessionStorage.setItem("dis_anim", false);
    history.push(`/map?search=${search}`);
  };

  /* const setSelected = (value) => {
        setSearch(value);
        setItemSelected(true);
    }; */

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter" || e.charCode === 13) {
      sendSearch();
    }
  };

  const sendSearchViaDropDown = (searchData) => {
    setSearch(searchData);
    setItemSelected(true);
    localStorage.setItem("flow", "searchdesktop");
    localStorage.setItem("modalClosed", false);
    sessionStorage.setItem("dis_anim", false);
    history.push(`/map?search=${searchData}`);
  };

  return (
    <>
      <main className={`${Style.main_wrapper} ${Style.active}`}>
        <div className={Style.main_wrapper_inner}>
          <div className={Style.lading_page_wrapper}>
            <div className={Style.lading_page_inner}>
              <div className={Style.lading_page_bg}>
                <img src={Assets.lading_banner} alt="" />
              </div>

              <div className={Style.lading_search_box}>
                <div className={Style.lading_search_heading}>
                  <h1>
                    Search for anything. <span>Anywhere.</span>
                  </h1>
                  <h4>On your own data / or ours.</h4>
                </div>

                <div className={Style.lading_search}>
                  <div className={Style.lading_search_main}>
                    <div className={Style.landing_search_left_icon}>
                      <span className="icon-search"></span>
                    </div>
                    <div className={Style.lading_search_input}>
                      <input
                        type="text"
                        placeholder="What are you looking for"
                        onChange={(e) => handleSearchChange(e.target.value)}
                        value={search}
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) =>
                          (e.target.placeholder = "What are you looking for")
                        }
                        onKeyPress={handleKeypress}
                      />
                    </div>

                    <div className={Style.lading_action_wrapper}>
                      <button
                        type="submit"
                        className={`${Style.lading_camera_icon} ${
                          search != "" && Style.active
                        }`}
                        onClick={sendSearch}
                      >
                        <span className="icon-send"></span>
                      </button>
                      {/* <button
                        className={`${Style.lading_camera_icon} ${
                          search == "" && Style.active
                        }`}
                        onClick={setCard}
                      >
                        <span className="icon-camera"></span>
                      </button> */}
                    </div>
                  </div>

                  {showCard ? <VideoSearch handleClose={hideCard} /> : null}

                  {search !== "" && !itemSelected && !listLoading && (
                    <div className={Style.landing_search_result}>
                      <div className={Style.landing_search_result_inner}>
                        {dropdownList.length >= 1 &&
                          dropdownList.map((res, i) => {
                            return (
                              <button
                                key={i}
                                className={Style.lading_search_result_item}
                                onClick={() => {
                                  sendSearchViaDropDown(res?.name);
                                }}
                              >
                                <div
                                  className={Style.landing_search_item_inner}
                                >
                                  <div className={Style.landing_image_wrapper}>
                                    <div
                                      className={
                                        Style.landing_search_icon_container
                                      }
                                    >
                                      <img src={res?.image} alt="" />
                                    </div>
                                  </div>
                                  <h3>{res?.name}</h3>
                                </div>
                              </button>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
                <div className={Style.lading_search_text}>
                  <small>Example: Mango tree, Red light Violation</small>
                </div>
              </div>

              <div
                className={Style.landing_apply_icon}
                id="homepage-footer-image"
                data-mobile-hide="true"
              >
                <a
                  href="https://play.google.com/store/apps/details?id=co.nayan.c3specialist_v2.production"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Assets.android_app_icon} alt="Mobile Nayan App" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default MainPage;
