import React from "react";
import BillBoardsUpdate from "../components/BillBoardsUpdate";
import Header from "../components/Header";


function BillBoardsPage() {
  return (
    <>
    <Header/>
    <BillBoardsUpdate/>
    </>
  );
}

export default BillBoardsPage;
