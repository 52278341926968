import { axiosInstance } from "../axios";
import { toast } from "react-toastify";

// For fetching auto search data
export const loadAutoSearch = async (search, setDropdown, setListLoading) => {
  setListLoading(true);
  setDropdown([]);
  try {
    const response = await axiosInstance.get(
      `/api/nayan/searches/suggestions?input=${search}`
    );
    setListLoading(false);
    const { data } = response;
    if (Array.isArray(data)) {
      setDropdown(data);
    } else {
      setDropdown([]);
    }
  } catch (error) {
    setListLoading(false);
    console.log(error);
    toast.error(
      /* error?.response?.data || */ "Something went wrong, Please try again."
    );
  }
};

// fetch City data for Dropdown
export const loadCity = async (setCityDropdown) => {
  try {
    const response = await axiosInstance.get("/api/nayan/searches/cities");
    const { data } = response;
    if (Array.isArray(data)) {
      setCityDropdown(data);
    } else {
      setCityDropdown([]);
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, Please try again.");
  }
};

// fetch Range Data for Dropdown
export const loadRange = async (setRangeDropdown) => {
  try {
    const response = await axiosInstance.get(
      "/api/nayan/searches/distance_ranges"
    );
    const { data } = response;
    if (Array.isArray(data)) {
      setRangeDropdown(data);
    } else {
      setRangeDropdown([]);
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong, Please try again.");
  }
};

// fetch Search Data
export const loadSearchData = async (props) => {
  const {
    input,
    selectCity,
    selectRange,
    startDate,
    endDate,
    setAllImageList,
    setTotalPages,
    setImageList,
    setLoading,
    dataPerPage,
    setPageNumber,
    setIsScrollDown,
    email,
    token,
    client,
    expiry,
  } = props;

  try {
    let headers = {};
    let isHeader = false;
    if (email && token && client && expiry) {
      isHeader = true;
      console.log("Hai from Api");
      headers = {
        headers: {
          "access-token": token,
          "token-type": "Bearer",
          uid: email,
          client,
          expiry,
        },
      };
    }
    const response = await axiosInstance.post(
      "/api/nayan/searches/search",
      {
        nayan_search: {
          input,
          city: selectCity || "",
          range: selectRange || "",
          start_date: startDate || "",
          end_date: endDate || "",
        },
      },
      isHeader && headers
    );

    const { data } = response;

    if (Array.isArray(data)) {
      setAllImageList(data);
      // For Pagination
      if (data.length >= 1) {
        let tempPages = Math.ceil(data.length / 10);
        setTotalPages(tempPages);
        setImageList(data.slice(0, dataPerPage));
        setIsScrollDown(true);
        setLoading(false);
      } else {
        setImageList([]);
        setTotalPages(1);
        setPageNumber(1);
        setLoading(false);
      }
    } else {
      setAllImageList([]);
      setImageList([]);
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
    setAllImageList([]);
    setImageList([]);
    console.log("search", error);
    console.log("Something went wrong, Please try again.");
  }
};

// check isLogin
export const isLogin = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("token")) {
    return JSON.parse(localStorage.getItem("token"));
  } else {
    return false;
  }
};

// register
export const register = async (
  setBtnloading,
  errorValues,
  setErrorValues,
  setRedirectToUser,
  dispatchLoginSignup,
  password,
  password_confirmation,
  email,
  name,
  company_name,
  designation
) => {
  setBtnloading(true);
  try {
    const { data } = await axiosInstance.post(`/api/nayan/registrations`, {
      password,
      password_confirmation,
      email,
      name,
      company_name,
      designation,
    });

    toast.success(
      "Your account has been successfully registered. Please login to use."
    );
    setRedirectToUser(true);
    dispatchLoginSignup({ type: "CLOSE_LOGIN_SIGNUP" });
  } catch (error) {
    setBtnloading(false);
    setErrorValues({
      ...errorValues,
      showError: true,
      error:
        error.response?.data?.message ||
        "Something went wrong, Please try again.",
    });
    //toast.error(error.response?.data?.message ||"Something went wrong, Please try again.");
  }
};
