import React from "react";
import Style from "./UseCases.module.scss";
import UseCasesContent from "../UseCasesContent";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

function UseCases() {
    return (
        <>
            <section className="slimbnr">
                <div className="container">
                    <header className="pagetitle">
                        <h1>Use Cases</h1>
                    </header>
                </div>
            </section>

            <section className={Style.usecasesec}>
                <div className="container">
                    <div
                        className={`${Style.casestabwrapper} ${Style.responsive_tabs}`}
                    >
                        <Tabs
                            defaultActiveKey="all"
                            id="uncontrolled-tab-example"
                            className={Style.nav_tabs}
                        >
                            <Tab
                                eventKey="all"
                                title="All"
                                className={Style.nav_item}
                            >
                                <UseCasesContent
                                    mobHead="All"
                                    deskHead="deskAll"
                                />
                            </Tab>
                            <Tab
                                eventKey="Traffic"
                                title="Traffic"
                                className={Style.nav_item}
                            >
                                <UseCasesContent
                                    mobHead="Traffic"
                                    deskHead="deskTraffic"
                                />
                            </Tab>
                            <Tab
                                eventKey="Infrastructure"
                                title="Infrastructure"
                                className={Style.nav_item}
                            >
                                <UseCasesContent
                                    mobHead="Infrastructure"
                                    deskHead="deskInfra"
                                />
                            </Tab>
                            <Tab
                                eventKey="Animals"
                                title="Animals"
                                className={Style.nav_item}
                            >
                                <UseCasesContent
                                    mobHead="Animals"
                                    deskHead="deskAnimals"
                                />
                            </Tab>
                            <Tab
                                eventKey="Covid"
                                title="Covid"
                                className={Style.nav_item}
                            >
                                <UseCasesContent
                                    mobHead="Covid"
                                    deskHead="deskCovid"
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>
        </>
    );
}

export default UseCases;
