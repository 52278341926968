import React, { useState, useEffect, useRef, useContext } from "react";
import { Context } from "../../context";
import { LoginSignupContext } from "../../context/loginSignupModal";
import { MapStateContext } from "../../context/mapStateControl";
import Assets from "../Layout/CommonLayout/Asset";
import GoogleMap from "./googlemap";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Style from "./Map.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import LoadingMap from "../LoadingMap";
import EditInputs from "../EditInputs";
import LoadingScreen from "../LoadingScreen";
import SubscriptionModel from "../SubscriptionModel";
import LoadingSearchPage from "../LoadingScreen/LoadingSearchPage";
import CircularProgress from "@mui/material/CircularProgress";
import {
  loadCity,
  loadRange,
  loadSearchData,
  loadAutoSearch,
} from "../../api/commonApi";
import Moment from "react-moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function Map() {
  // State for User
  const {
    state: { user },
  } = useContext(Context);

  // State for Login/Signup Modal
  const { dispatchLoginSignup } = useContext(LoginSignupContext);

  // State for Info Window
  const { dispatchMapState } = useContext(MapStateContext);

  const history = useHistory();

  // Get the search Keyword from the Url
  const searchQuery = useLocation().search;
  const searchName = new URLSearchParams(searchQuery).get("search");

  const ref = useRef();
  const smallScreenRef = useRef();

  const [isScrollUp, setIsScrollUp] = useState(false);

  const [isScrollDown, setIsScrollDown] = useState(false);

  const [search, setSearch] = useState("");
  const [dropdownList, setDropdown] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [modelImage, setImage] = useState(Assets.one);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [loadingSearchAnimation, setLoadingSearchAnimation] = useState(true);
  const [showModal, setModal] = useState(false);

  // Filter State Start

  const [startDate, setStartDate] = useState(moment().startOf("year"));
  const [endDate, setEndDate] = useState(moment());
  const [cityList, setCityDropdown] = useState([]);
  const [rangeList, setRangeDropdown] = useState([]);
  const [selectCity, setSelectCity] = useState("");
  const [selectRange, setSelectRange] = useState("");
  // Filter End

  // Pagination Start
  const [allImageList, setAllImageList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dataPerPage] = useState(10);
  // Pagination End

  /*  const [isMapPagination, setIsMapPagination] = useState(true); */

  const [itemSelected, setItemSelected] = useState(false);
  const [filterSelected, setFilter] = useState(true);

  useEffect(() => {
    const disableAnimation = sessionStorage.getItem("dis_anim");
    if (disableAnimation === "true") {
      setLoadingScreen(false);
      setPageLoaded(true);
      setLoadingSearchAnimation(false);
    } else {
      setTimeout(() => {
        setLoadingScreen(false);
        setPageLoaded(true);
        setLoadingSearchAnimation(false);
      }, 5000);
      sessionStorage.setItem("dis_anim", true);
      return () => {
        setTimeout(() => {
          setPageLoaded(false);
        }, 5000);
      };
    }
  }, []);

  useEffect(() => {
    loadCity(setCityDropdown);
    loadRange(setRangeDropdown);
  }, []);

  useEffect(() => {
    setLoading(true);
    //Check the SearchName is present or not
    if (searchName) {
      // For Auth User
      if (user !== null) {
        // for checking filter values from session
        const checkFilter = JSON.parse(sessionStorage.getItem("filterData"));

        // updating filter values to their state
        if (checkFilter) {
          setSelectCity(checkFilter?.city);
          setSelectRange(checkFilter?.range);
          setStartDate(checkFilter?.startDate);
          setEndDate(checkFilter?.endDate);
          sessionStorage.removeItem("filterData");
        }

        // fetch search data with auth headers
        loadSearchData({
          input: searchName,
          selectCity: checkFilter?.city || "",
          selectRange: checkFilter?.range || "",
          startDate,
          endDate,
          setAllImageList,
          setTotalPages,
          setImageList,
          setLoading,
          dataPerPage,
          setPageNumber,
          setIsScrollDown,
          email: user?.email,
          token: user?.token,
          client: user?.client,
          expiry: user?.exp,
        });
        // For UnAuthenticated user
      } else {
        // condition to avoid re-rendering
        if (!JSON.parse(localStorage.getItem("token"))) {
          // fetch search data without auth headers
          loadSearchData({
            input: searchName,
            selectCity,
            selectRange,
            startDate,
            endDate,
            setAllImageList,
            setTotalPages,
            setImageList,
            setLoading,
            dataPerPage,
            setPageNumber,
            setIsScrollDown,
          });
        }
      }
    } else {
      setLoading(false);
    }
  }, [searchName, user]);

  /* const setModelImage = (image) => {
        setImage(image.display_image_url);
        setModal(true);
        setLoadingAnimation(true); 
         setTimeout(()=>{
        setLoadingAnimation(false);
        setModal(true);
      },5000) 
    };
 */
  const fetchDataByFilter = async () => {
    // For maintaining stateS Start
    setLoading(true);
    setImageList([]);
    setAllImageList([]);
    setTotalPages(1);
    setTotalPages(1);
    setPageNumber(1);
    setIsScrollDown(false);
    setIsScrollUp(false);
    // For maintaining stateS End

    // Fetch Search filter data
    loadSearchData({
      input: searchName,
      selectCity,
      selectRange,
      startDate,
      endDate,
      setAllImageList,
      setTotalPages,
      setImageList,
      setLoading,
      dataPerPage,
      setPageNumber,
      setIsScrollDown,
      email: user?.email,
      token: user?.token,
      client: user?.client,
      expiry: user?.exp,
    });
  };

  const hidemodal = () => {
    setModal(false);
  };

  // Search Functionality Start

  const handleSearchChange = (value) => {
    setSearch(value);
    // For Passing empty value
    if (value.length === 0) {
      setItemSelected(true);
      return;
    }
    //trim will remove white spaces.
    if (value.trim().length === 0) {
      setItemSelected(true);
      return;
    }
    setItemSelected(false);
    // Else Load Auto Search
    loadAutoSearch(value, setDropdown, setListLoading);
  };

  const sendSearch = () => {
    localStorage.setItem("flow", "searchdesktop");
    localStorage.setItem("modalClosed", false);
    sessionStorage.setItem("dis_anim", true);
    return window.location.replace(`/map?search=${search}`);
    //history.push(`/map?search=${search}`);
  };

  const sendSearchViaDropDown = (searchData) => {
    setSearch(searchData);
    setItemSelected(true);
    localStorage.setItem("flow", "searchdesktop");
    localStorage.setItem("modalClosed", false);
    sessionStorage.setItem("dis_anim", true);
    return window.location.replace(`/map?search=${searchData}`);
    //history.push(`/map?search=${search}`);
  };

  // For handling enter button for Search
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter" || e.charCode === 13) {
      sendSearch();
    }
  };

  // Search Functionality End

  const nextPage = (value, type, scrollOffset) => {
    if (value <= totalPages && value > 1) {
      // If scroll down , it shows up arrow
      if (scrollOffset === -133) setIsScrollUp(true);
      ref.current.scrollTop -= scrollOffset;
      smallScreenRef.current.scrollTop -= scrollOffset;
      // If it goes fully up, it hides up arrow
      if (ref.current.scrollTop === 0 && scrollOffset === 133)
        setIsScrollUp(false);
      setPageNumber(value);
      let startData;
      let endData;
      endData = value * dataPerPage;
      startData = endData - dataPerPage;
      let results = [];
      // Increment
      if (type === 1) {
        results = allImageList?.slice(startData, endData);
        results = [...imageList, ...results];
      }
      // Decrement
      else {
        results = [...imageList];
        results.splice(results.length - 10, 10);
      }
      setImageList(results);
      if (value === totalPages) {
        setIsScrollDown(false);
      } else if (value === 1) {
        setIsScrollUp(false);
      }
    }
    return;
  };

  const showFilters = () => {
    filterSelected ? setFilter(false) : setFilter(true);
  };

  const onScrollBoard = (e) => {
    if (e.target.scrollTop) {
      setIsScrollUp(true);
    } else {
      setIsScrollUp(false);
    }
    setIsScrollDown(true);
  };

  // Only for Small Screen windows
  const onScrollBoardSmallScreen = (e) => {
    if (e.target.scrollTop) {
      setIsScrollUp(true);
    } else {
      setIsScrollUp(false);
    }
    setIsScrollDown(true);
  };

  const showNotFound = () => (
    <div className={Style.not__found}>
      <h3>No Results Found!</h3>
    </div>
  );

  const checkUsertoRedirect = (image) => {
    dispatchMapState({ type: "CLOSE_INFO_MAP" });
    if (selectCity || selectRange) {
      sessionStorage.setItem(
        "filterData",
        JSON.stringify({
          city: selectCity,
          range: selectRange,
          startDate,
          endDate,
        })
      );
    }

    if (user !== null) {
      localStorage.setItem(
        "backBillMap",
        JSON.stringify(history?.location?.pathname + history?.location?.search)
      );
      return history.push(
        {
          pathname: "/billBoardDescription",
          search: `?name=${image?.id || ""}&date=${image?.created_at}`,
        },
        {
          image_url: image?.display_image_url,
        }
      );
    } else {
      //setImage(image.display_image_url);
      //setModal(true);
      sessionStorage.setItem("isMapPage", true);
      sessionStorage.setItem(
        "mapInfo",
        JSON.stringify({
          name: image?.id || "",
          date: image?.created_at,
          url: image?.display_image_url,
        })
      );

      dispatchLoginSignup({ type: "LOGIN" });
    }
  };

  const showMapDetails = (item) => {
    dispatchMapState({ type: "CLOSE_INFO_MAP" });
    const data = {
      name: item?.name || "",
      created_at: item?.created_at,
      location: {
        lat: item.latitude,
        lng: item.longitude,
      },
      address: item.address,
    };
    dispatchMapState({ type: "SHOW_INFO_MAP", payload: data });
  };

  return (
    <>
      {loadingAnimation && <LoadingScreen />}

      {loadingScreen && <LoadingMap />}

      {loadingSearchAnimation && <LoadingSearchPage />}

      <main
        className={`${Style.main_wrapper} ${pageLoaded ? "" : Style.active}`}
      >
        <div className={Style.main_wrapper_inner}>
          <div className={Style.map_wrapper}>
            <div className={Style.map_wrapper_inner}>
              <div className={Style.map_main}>
                <GoogleMap
                  mapDatas={imageList}
                  checkUsertoRedirect={checkUsertoRedirect}
                />
              </div>
            </div>
            <div className={Style.map_filter}>
              <div className={Style.map_filter_header}>
                <div className={Style.map_search_icon}>
                  <span className="icon-search"></span>
                </div>

                <div className={Style.map_input_wrapper}>
                  <input
                    type="text"
                    placeholder={searchName}
                    onChange={(e) => {
                      handleSearchChange(e.target.value);
                    }}
                    value={search}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = searchName)}
                    onKeyPress={handleKeypress}
                  />
                </div>

                <div className={Style.map_action_wrapper}>
                  <button
                    onClick={() => sendSearch()}
                    className={`${Style.map_message_icon} ${
                      search !== "" && Style.active
                    }`}
                  >
                    <span className="icon-send"></span>
                  </button>
                  {/* <button
                                        className={`${Style.map_camera_icon} ${
                                            search == "" && Style.active
                                        }`}
                                    >
                                        <span className="icon-camera"></span>
                                    </button> */}
                </div>
              </div>

              {search !== "" && !itemSelected && !listLoading && (
                <div className={Style.landing_search_result}>
                  <div className={Style.landing_search_result_inner}>
                    {dropdownList.length >= 1 &&
                      dropdownList.map((res, i) => {
                        return (
                          <button
                            key={i}
                            className={Style.lading_search_result_item}
                            onClick={() => {
                              sendSearchViaDropDown(res?.name);
                            }}
                          >
                            <div className={Style.landing_search_item_inner}>
                              <div className={Style.landing_image_wrapper}>
                                <div
                                  className={
                                    Style.landing_search_icon_container
                                  }
                                >
                                  <img src={res?.image} alt="" />
                                </div>
                              </div>
                              <h3>{res?.name}</h3>
                            </div>
                          </button>
                        );
                      })}
                  </div>
                </div>
              )}

              <div className={Style.map_info_container}>
                <div
                  onScroll={onScrollBoardSmallScreen}
                  ref={smallScreenRef}
                  className={Style.map_info_inner_scroll}
                >
                  <div className={Style.map_info_head}>
                    <h3>{searchName || ""}</h3>
                    <p>{allImageList.length || 0} search found</p>
                  </div>

                  <div className={Style.map_info_filter}>
                    <div
                      className={Style.map_info_filter_head}
                      onClick={showFilters}
                    >
                      <h4>
                        Filters
                        {filterSelected ? (
                          <ArrowDropDownIcon
                            style={{
                              color: "#adb5bd",
                            }}
                          />
                        ) : (
                          <ArrowRightIcon
                            style={{
                              color: "#adb5bd",
                            }}
                          />
                        )}
                      </h4>
                    </div>
                    {filterSelected ? (
                      <EditInputs
                        selectCity={selectCity}
                        selectRange={selectRange}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        cityList={cityList}
                        rangeList={rangeList}
                        setSelectCity={setSelectCity}
                        setSelectRange={setSelectRange}
                        fetchDataByFilter={fetchDataByFilter}
                      />
                    ) : null}
                  </div>

                  {!loading && imageList.length <= 0 && showNotFound()}

                  <div
                    onScroll={onScrollBoard}
                    ref={ref}
                    className={Style.filter_result_wrapper}
                  >
                    {loading && (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    )}

                    {/*  {!loading &&
                                            imageList.length <= 0 &&
                                            showNotFound()} */}

                    {imageList.map((image) => {
                      return (
                        <div
                          key={image?.id}
                          className={Style.filter_result_item}
                        >
                          <div className={Style.filter_result_image}>
                            <div
                              className={Style.filter_result_image_container}
                            >
                              <img
                                style={{
                                  cursor: "pointer",
                                }}
                                src={image.display_image_url}
                                alt=""
                                onMouseEnter={() => showMapDetails(image)}
                                onClick={() => checkUsertoRedirect(image)}
                              />
                            </div>
                          </div>
                          <div className={Style.filter_result_content}>
                            <h4>{image?.name}</h4>
                            <p>
                              <Moment format="hh:mm A DD/MM/YYYY">
                                {image.created_at}
                              </Moment>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={Style.read_more_wrapper}>
                  {imageList.length >= 1 &&
                    pageNumber <= totalPages &&
                    isScrollDown && (
                      <button onClick={() => nextPage(pageNumber + 1, 1, -133)}>
                        <span className="icon-arrow-bottom"></span>
                      </button>
                    )}
                  {imageList.length >= 1 && isScrollUp && (
                    <button
                      onClick={() => nextPage(pageNumber - 1, 2, 133)}
                      className="icon__rotate"
                    >
                      <span className="icon-arrow-bottom"></span>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className={Style.floating_right_icon}>
              <div className={Style.floating_right_icon_inner}>
                <div className={Style.layer_wrapper}>
                  <button>
                    <span className="icon-map-01"></span>
                  </button>
                </div>

                <div className={Style.message_wrapper}>
                  <button>
                    <span className="icon-message"></span>
                  </button>
                </div>

                <div className={Style.direction_wrapper}>
                  <button>
                    <span className="icon-direction"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubscriptionModel
          show={showModal}
          handleClose={hidemodal}
          image={modelImage}
        />
      </main>
    </>
  );
}

export default Map;
