import React, { useState } from "react";
import Assets from "../Layout/CommonLayout/Asset";
import Style from "./ShareDetails.module.scss";
import Button from "@restart/ui/esm/Button";

function ShareDetails({hideMediaDetails}) {
    const [shareMedias,setShareMedias] = useState([{image:Assets.share01},{image:Assets.share02},{image:Assets.share03},{image:Assets.share04},{image:Assets.share05},{image:Assets.share06}])
    const hideMedias = () =>{
        hideMediaDetails();
    }
  return (
    <div className={Style.share_wrapper}>
    <div className={Style.share_wrapper_inner}>

        <div className={Style.social_media_wrapper}>
            <ul>
                {shareMedias.map((medias,index)=>{
                    return(
                    <li key={index}>
                        <a href="">
                            <img src={medias.image} alt=""/>
                        </a>
                    </li>
                    )
                })}
            </ul>
        </div>


        <div className={Style.copy_wrapper}>
            <h3>Or Copy Link</h3>
            <div className={Style.copy_input}>
                <input type="text"/>
                <Button bsprefix="custom" className={Style.copy}>Copy</Button>
            </div>
        </div>

        <div className={Style.action_wrapper}>
            <Button bsprefix="custom" className={Style.cancel_btn} onClick={hideMedias}>
                Cancel 
            </Button>
            <Button bsprefix="custom" className={Style.share_btn}>
                Share
            </Button>
        </div>


    </div>
</div>
        );
}

export default ShareDetails;