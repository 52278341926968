import React, {useState} from "react";
import { Button } from "react-bootstrap";
import Style from "./ForgotPassword.module.scss";
import { Link, useHistory } from "react-router-dom";
import CommonLayout from "../Layout/CommonLayout";
import Assets from "../Layout/CommonLayout/Asset";


function ForgotPassword() {

    const history = useHistory();

    const [values, setValues] = useState({
        email: '',
        btnloading: false
    });

    const {email, btnloading} = values;

    // For changing login values
    const handleChange = name => event => {
        setValues({...values, [name]:event.target.value, btnloading: false});
    }

    // For Login Function
    const clickSubmit = async(e) => {
        e.preventDefault();
    }

  return (
    <>

        <CommonLayout>

              <div className={Style.login_wrapper}>
                    <div className={Style.login_inner}>
                        <div className={Style.back_to_page}>
                            <Button bsPrefix="custom" onClick={() => history.push("/")}>
                            </Button>
                        </div>
                        <div className={Style.logo_wrapper_inner}>
                            <div className={Style.container}>
                                <img src={Assets.logoMain} alt="" onClick={()=>history.push("/")}/>
                            </div>
                        </div>


                        <div className={Style.form_wrapper}>
                            <form onSubmit={clickSubmit} className={Style.form_wrapper_inner}>

                                <div className={Style.display__message}>
                                    <h3>Forgot Your Password?</h3>
                                    <p>No worries! Enter your email, we will send you a reset link</p>
                                </div>
                                <div className={`${Style.from_group} mb-3`}>
                                    <label htmlFor="">Email ID</label>
                                    <input type="email" onChange={handleChange('email')} value={email} required />
                                </div>

                                <div className={Style.from_group}>
                                    <Button disabled={btnloading} bsPrefix="custom" type="submit" className={Style.login_btn} >
                                    {btnloading ? "Loading..." : "Submit"}
                                    </Button>
                                </div>

                                <div className={Style.action_wrapper}>
                                    <p>New member? <Link to="/userSignup">Sign Up</Link></p>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>
               
        </CommonLayout>
    </>
  );
}

export default ForgotPassword;


