import React from "react";
import BoardDescription from "../components/BoardDescription";
import Header from "../components/Header";

function BillBoardDescriptionPage() {
  return (
    <>
      <Header />
      <BoardDescription />
    </>
  );
}

export default BillBoardDescriptionPage;
