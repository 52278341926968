import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Fragment,
} from "react";
import Style from "./Header.module.scss";
import Assets from "../Layout/CommonLayout/Asset";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Context } from "../../context";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import LoginPopup from "../LoginPopup";
import SignupModal from "../SignUpModal";
import AccountSettings from "../AccountSettings";
import { LoginSignupContext } from "../../context/loginSignupModal";

function Header() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();

  const menuRef = useRef();

  const {
    loginSignupState: { isLogin },
    dispatchLoginSignup,
  } = useContext(LoginSignupContext);

  const {
    state: { user },
    dispatch,
  } = useContext(Context);

  // Account Menu
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  //Model Login/Signup
  const [showModal, setModal] = useState(false);
  const [showSignUpModal, setSignUpModal] = useState(false);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // logout function
  const logout = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("token");
    toast.success("Your account has been successfully Logout.");
    history.push("/");
  };

  // open Login Modal
  const openLogin = (e) => {
    e.preventDefault();
    setModal(true);
    dispatchLoginSignup({ type: "LOGIN" });
    localStorage.setItem("loginModal", false);
  };

  // Open Signup Modal
  const openSignUp = (e) => {
    e.preventDefault();
    setSignUpModal(true);
    dispatchLoginSignup({ type: "SIGNUP" });
    localStorage.setItem("signupModal", false);
  };

  useEffect(() => {
    if (user !== null) setModal(false);
  }, [user]);

  // For closing menu, whenver we click outside
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          //setActions(false);
          if (event.target.id !== "hamb___btn") {
            setMenuOpen(false);
          }
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const securityIcon = (
    <div className={Style.action_item}>
      <a
        href="https://traffic.nayan.co/"
        target="_blank"
        rel="noreferrer"
        className={Style.login_link}
      >
        Traffic
      </a>
    </div>
  );

  useOutsideAlerter(menuRef);

  return (
    <header
      className={`${Style.header_wrapper} ${
        location.pathname === "/" && `${Style.active} ${Style.home_page}`
      } ${
        location.pathname === "/map" && `${Style.showmenu} ${Style.home_page}`
      }`}
    >
      <div className={Style.header_inner}>
        <div className={`${Style.hamburger_menu} ${menuOpen && Style.active}`}>
          <div
            className={Style.hamburger_icon}
            // onclick="hamburger()"
            id="hamb___btn"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className={Style.hamburger_item_wrapper}>
            <div
              className={Style.close_icon}
              // onclick="hamburger()"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              /* ref={menuRef} */
            >
              <span></span>
              <span></span>
            </div>
            <ul ref={menuRef} className={Style.hamburger_item_inner}>
              <li
                style={{ backgroundColor: "inherit" }}
                className={Style.hamburger_item}
              >
                <Link className={Style.linkMenu} to="/">
                  Home
                </Link>
              </li>
              <li className={Style.hamburger_item}>
                <a href="#/">About Us</a>
              </li>
              <li className={Style.hamburger_item}>
                <a href="#/">Our Cases</a>
              </li>
              <li className={Style.hamburger_item}>
                <a href="#/">Market Place</a>
              </li>
              {user !== null &&
                location.pathname !== "/" &&
                location.pathname !== "/map" && (
                  <li className={Style.hamburger_item} id="logout__mobile">
                    <a
                      href="#/"
                      className={Style.linkMenu}
                      style={{ cursor: "pointer" }}
                      onClick={logout}
                    >
                      Logout
                    </a>
                  </li>
                )}
            </ul>
          </div>
        </div>

        <div className={Style.logo_wrapper}>
          <div className={Style.logo_container}>
            <img
              src={Assets.logoMain}
              alt="logo Nayanz"
              onClick={() => history.push("/")}
            />
          </div>
        </div>

        {user !== null && (
          <div
            className={`${Style.profile__wrapper__billboard} ${
              (location.pathname === "/map" || location.pathname === "/") &&
              `${Style.show_this}`
            } ${
              location.pathname === "/" && `${Style.profile__wrapperfor__home}`
            }`}
          >
            <div className={Style.profile__wrapper__button}>
              <AccountSettings userName={user?.name || ""} logout={logout} />
            </div>
          </div>
        )}

        {user === null && (
          <div
            className={`${Style.profile__wrapper__billboard} ${
              location.pathname === "/map" && `${Style.show_this}`
            }`}
          >
            <div className={Style.profile__wrapper__button}>
              <AccountSettings />
            </div>
          </div>
        )}
        {user === null && (
          <div
            className={`${Style.extra_info_wrapper} ${
              location.pathname === "/map" && `${Style.nil}`
            }`}
          >
            <div className={Style.action_wrapper}>
              {securityIcon}
              <div className={Style.action_item}>
                <a
                  href="#/"
                  className={Style.login_link}
                  onClick={(e) => openLogin(e)}
                >
                  Log In
                </a>
              </div>
              <div className={Style.action_item}>
                <a
                  href="#/"
                  className={Style.signup_link}
                  onClick={(e) => openSignUp(e)}
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        )}

        {/* Only for billboard Pages  */}
        {location.pathname !== "/" &&
          location.pathname !== "/map" &&
          user !== null && (
            <div className={Style.profile__wrapperfor__billboard}>
              <Tooltip
                className={
                  location.pathname === "/map"
                    ? Style.profile__wrapper__button
                    : Style.profile__wrapper__button__map
                }
                title="Account settings"
              >
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                  <Avatar sx={{ width: 32, height: 32 }} />
                  <div className={Style.profile__dots}></div>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <Link style={{ color: "black" }} to="/billboardssearch">
                  <MenuItem>
                    <Avatar /> {user?.name || ""}
                  </MenuItem>
                </Link>
                <Divider />
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
      </div>

      <LoginPopup show={showModal} handleClose={setModal} />
      <SignupModal show={showSignUpModal} handleClose={setSignUpModal} />
    </header>
  );
}

export default Header;
