import React from "react";
import { useState } from "react";
import Assets from "../Layout/CommonLayout/Asset";
import Style from "./CategorySlider.module.scss";
import Slider from "react-slick";

function CategorySlider() {
    const [categoryList,setCategoryList] = useState([{image:Assets.category_01,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_02,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_03,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_04,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_05,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_06,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_07,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.category_01,itemTitle:"Orci varius natoque penatibus",price:2}]);
    const settings = {
        
        infinite: false,
        arrows: true,
        speed: 800,
        slidesToShow: 4,
        rows: 2,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3
            }

        }, {

        breakpoint: 991,
        settings: {
            slidesToShow: 2
        }

        }, {

            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                rows: 1
            }

        }]
    };

  return (

      <Slider {...settings}  className={Style.category_slider}>

        {categoryList.map((category)=>{
            return(
            <div className={Style.slidecols}>
                <div className={Style.slide_item}>
                    <figure className={Style.imgsec}><img src={category.image} alt=""/></figure>
                    <div className={Style.detail_sec}>
                        <header className={Style.itemtitle}>{category.itemTitle} </header>
                        <div className={Style.price}>${category.price}</div>
                        <div className={Style.dwnld_icon}><a href="#"><img src={Assets.direct_download} alt=""/></a></div>
                    </div>
                </div>
            </div>
            )
        })}
        {categoryList.map((category)=>{
            return(
            <div className={Style.slidecols}>
                <div className={Style.slide_item}>
                    <figure className={Style.imgsec}><img src={category.image} alt=""/></figure>
                    <div className={Style.detail_sec}>
                        <header className={Style.itemtitle}>{category.itemTitle} </header>
                        <div className={Style.price}>${category.price}</div>
                        <div className={Style.dwnld_icon}><a href="#"><img src={Assets.direct_download} alt=""/></a></div>
                    </div>
                </div>
            </div>
            )
        })}
    </Slider>

);
}

export default CategorySlider;