import GoogleMap from "../../Map/googlemap";
import React, { useEffect, useState } from "react";
import Style from "./MapLayout.module.scss";
import Assets from "../../Layout/CommonLayout/Asset";

function MapLayout({ children }) {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [search, setSearch] = useState("");
    const [dropdownList, setDropdown] = useState([
        { name: "Mango tree", image: Assets.results01 },
        { name: "Mango tree", image: Assets.results02 },
        { name: "Mango tree", image: Assets.results03 },
        { name: "Mango tree", image: Assets.results04 },
        { name: "Mango tree", image: Assets.results05 },
    ]);
    const [itemSelected, setItemSelected] = useState(false);

    const setSelected = (value) => {
        setSearch(value);
        setItemSelected(true);
    };

    const setDropdownSearch = (value) => {
        setSearch(value);
        search == "" ? setItemSelected(false) : setItemSelected(true);
    };

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true);
        }, 50);
        return () => {
            setTimeout(() => {
                setPageLoaded(false);
            }, 100);
        };
    }, []);

    return (
        <>
            <main
                className={`${Style.main_wrapper} ${
                    pageLoaded ? "" : Style.active
                }`}
            >
                <div className={Style.main_wrapper_inner}>
                    <div className={Style.map_wrapper}>
                        <div className={Style.map_wrapper_inner}>
                            <div className={Style.map_main}>
                                <GoogleMap />
                            </div>
                        </div>

                        <div className={Style.map_filter}>
                            <div className={Style.map_filter_header}>
                                <div className={Style.map_search_icon}>
                                    <span className="icon-search"></span>
                                </div>

                                <div className={Style.map_input_wrapper}>
                                    <input
                                        type="text"
                                        placeholder="Mac D Billboard"
                                        onChange={(e) => {
                                            setDropdownSearch(e.target.value);
                                        }}
                                        value={search}
                                        onFocus={(e) =>
                                            (e.target.placeholder = "")
                                        }
                                        onBlur={(e) =>
                                            (e.target.placeholder =
                                                "Mac D Billboard")
                                        }
                                    />
                                </div>

                                <div className={Style.map_action_wrapper}>
                                    <button
                                        className={`${Style.map_message_icon} ${
                                            search != "" && Style.active
                                        }`}
                                    >
                                        <span className="icon-send"></span>
                                    </button>
                                    <button
                                        className={`${Style.map_camera_icon} ${
                                            search == "" && Style.active
                                        }`}
                                    >
                                        <span className="icon-camera"></span>
                                    </button>
                                </div>
                            </div>

                            {search != "" && !itemSelected && (
                                <div className={Style.landing_search_result}>
                                    <div
                                        className={
                                            Style.landing_search_result_inner
                                        }
                                    >
                                        {dropdownList.map((res) => {
                                            return (
                                                <button
                                                    className={
                                                        Style.lading_search_result_item
                                                    }
                                                    onClick={() => {
                                                        setSelected(res.name);
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            Style.landing_search_item_inner
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                Style.landing_image_wrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    Style.landing_search_icon_container
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        res.image
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <h3>{res.name}</h3>
                                                    </div>
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}

                            <div className={Style.map_info_container}>
                                <div className={Style.map_info_inner_scroll}>
                                    {children}
                                </div>
                            </div>
                        </div>

                        <div className={Style.floating_right_icon}>
                            <div className={Style.floating_right_icon_inner}>
                                <div className={Style.layer_wrapper}>
                                    <button>
                                        <span className="icon-map-01"></span>
                                    </button>
                                </div>

                                <div className={Style.message_wrapper}>
                                    <button>
                                        <span className="icon-message"></span>
                                    </button>
                                </div>

                                <div className={Style.direction_wrapper}>
                                    <button>
                                        <span className="icon-direction"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <button className={Style.send_message_item}>SEND MESSAGE</button>
        </>
    );
}

export default MapLayout;
