import React from "react";
import CardDetails from "../components/CardDetails";

function PaymentPage() {
  return (
    <CardDetails/>
  );
}

export default PaymentPage;
