
import React,{useState} from "react";
import Style from "./MarketPlace.module.scss";
import Assets from "../Layout/CommonLayout/Asset";
import FilterSliders from "../FilterSliders";
import CategorySlider from "../CategorySlider";
import NewCategorySlider from "../NewCategorySlider";


function MarketPlace() {
    const [relatedList,setRelatedList] = useState([{image:Assets.related_01,itemTitle:"Orci varius natoque penatibus",price:2},{image:Assets.related_02,itemTitle:"Orci varius natoque penatibus",price:2}])
   
    return (
        <>

    <div className={Style.bg}>
        <section className={Style.bnrsec} 
        // style="background-image: url('assets/images/marketplace/banner/bnr-img.png');"
        >
            <div className={Style.container}>
                <div className={Style.bnrwrapper}>
                    <div className={Style.row}>
                        <div className="col-lg-5">
                            <div className={Style.bnrtext}>
                                <h1>Online Marketplace</h1>
                                <p>Excepteur sint occaecat cupidatat non proident, sunt iculpa qui officia deserunt mollit anim est. laborum sed perspciatis unde...</p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className={Style.bnrimgs}>
                                <div className={Style.bimgcol}>
                                    <figure className={Style.bimg}><img src={Assets.img_01} alt=""/></figure>
                                </div>
                                <div className={Style.bimgcol}>
                                    <figure className={Style.bimg}><img src={Assets.img_02} alt=""/></figure>
                                    <figure className={Style.bimg}><img src={Assets.img_03} alt=""/></figure>
                                </div>
                                <div className={Style.bimgcol}>
                                    <figure className={Style.bimg}><img src={Assets.img_04} alt=""/></figure>
                                    <figure className={Style.bimg}><img src={Assets.img_05} alt=""/></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className={Style.filter_fold}>
            <div className={Style.container}>
                <div className={Style.row}>
                    <div className="col-lg-3 d-flex align-items-center">
                        <div className={Style.filtersec}>
                            <div className={Style.filters}>
                                <button className={`${Style.filterbtn} ${Style.filter}`}><img src={Assets.filter} alt=""/> Filters</button>
                                <button className={`${Style.filterbtn} ${Style.price}`}>Price</button>
                                <button className={`${Style.filterbtn} ${Style.category}`}>Categories </button>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-12 col-lg-9">
                        <div>
                            <FilterSliders/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className={Style.categories}>
            <div className={Style.container}>
                <div className={Style.row}>
                    <div className="col-6"><header className={Style.sec_title}>Categories One</header></div>
                    <div className="col-6"><div className={Style.slide_detail}><span className={Style.counts}>Show 48</span> <button className="slick-prev slick-arrow cprev"> <span></span></button> <button className="slick-next slick-arrow cnext"> <span></span></button></div></div>
                </div>
                <CategorySlider />
            </div>
        </section>

        <section className={Style.categories}>
            <div className={Style.container}>
                <div className={Style.row}>
                    <div className="col-6"><header className={Style.sec_title}>Categories Two</header></div>
                    <div className="col-6"><div className={Style.slide_detail}><span className={Style.counts}>Show 48</span>  <button className="slick-prev slick-arrow c2prev"> <span></span></button> <button className="slick-next slick-arrow c2next"> <span></span></button></div>
                </div>
            </div>
            <NewCategorySlider/>
        </div>
        </section>


        <section className={Style.related_items}>
            <div className={Style.container}>
                <div className={Style.row}>
                    {relatedList.map((items)=>{
                        return(
                        <div className="col-md-6">
                        <div className={Style.single_item}>
                            <figure className={Style.imgsec}><img src={items.image} alt=""/></figure>
                            <div className={Style.detail_sec}>
                                <header className={Style.itemtitle}>{items.itemTitle}</header>
                                <div className={Style.price}>${items.price}</div>
                                <div className={Style.dwnld_icon}><a href="#"><img src={Assets.direct_download} alt=""/></a></div>
                            </div>
                        </div>
                        </div>
                        )
                    })}
                  
                </div>
            </div>
        </section>
    </div>

    </>

);
}

export default MarketPlace;