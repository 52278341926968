import "./App.css";
import "./styles/scss/main.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";

import PrivateRoute from "./auth/PrivateRoute";
import MainPageView from "./pages/MainPageView";
import MapPage from "./pages/MapPage";
import UserLoginPage from "./pages/UserLoginPage";
import UserSignupPage from "./pages/UserSignupPage";
import PaymentPage from "./pages/PaymentPage";
import BillBoardsPage from "./pages/BillBoardsPage";
import ConfigureJobPage from "./pages/ConfigureJobPage";
import RecommendationsPage from "./pages/RecommendationsPage";
import BillBoardDescriptionPage from "./pages/BillBoardDescriptionPage";
import BillBoardSearchPage from "./pages/BillBoardSearchPage";
import LoadingPage from "./pages/LoadingPage";
import DirectionMapPage from "./pages/DirectionMapPage";
import LoadingMapPage from "./pages/LoadingMapPage";
import MarketPlacePage from "./pages/MarketPlacePage";
import AboutUsPage from "./pages/AboutUsPage";
import UseCasesPage from "./pages/UseCasesPage";
import UseCaseDetail from "./pages/UseCaseDetail";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { Context } from "./context";
import { saveClientIp } from "./api/userApi";

function App() {
  const [clientIp, setClientIp] = useState("");
  const {
    state: { user },
  } = useContext(Context);

  const geoLocationUrl = process.env.REACT_APP_GEOLOCATION_URL;

  const fetchUserIP = useCallback(async () => {
    const { data } = await axios.get(geoLocationUrl);
    const ip = data["IPv4"];
    if (ip) {
      setClientIp(ip);
      await saveClientIp({
        ...user,
        ip: ip,
      });
    }
  }, [user, geoLocationUrl]);

  useEffect(() => {
    if (user) {
      fetchUserIP();
    }
  }, [fetchUserIP, user]);

  return (
    <Router>
      <Suspense fallback={<></>}>
        <Switch>
          <Route exact path="/" component={MainPageView} />
          <Route exact path="/map" component={MapPage} />
          <Route exact path="/userLogin" component={UserLoginPage} />
          <Route exact path="/userSignup" component={UserSignupPage} />
          <PrivateRoute exact path="/billboards" component={BillBoardsPage} />
          <Route exact path="/configureJob" component={ConfigureJobPage} />
          <Route
            exact
            path="/Recommendations"
            component={RecommendationsPage}
          />
          <PrivateRoute
            exact
            path="/billBoardDescription"
            component={BillBoardDescriptionPage}
          />
          <Route exact path="/loadingScreen" component={LoadingPage} />
          <Route exact path="/directionMap" component={DirectionMapPage} />
          <Route exact path="/payment" component={PaymentPage} />
          <PrivateRoute
            exact
            path="/billboardssearch"
            component={BillBoardSearchPage}
          />
          <Route exact path="/loadingmap" component={LoadingMapPage} />
          <Route exact path="/marketplace" component={MarketPlacePage} />
          <Route exact path="/aboutus" component={AboutUsPage} />
          <Route exact path="/usecases" component={UseCasesPage} />
          <Route exact path="/usecasedetail" component={UseCaseDetail} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
