import React, { useState, useEffect, useContext, memo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api";
import ShowInfoWindow from "./showInfoWindow";
import { MapStateContext } from "../../context/mapStateControl";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function MyComponent({ checkUsertoRedirect, mapDatas }) {
  // State for Info Window
  const {
    mapState: { showInfo },
  } = useContext(MapStateContext);

  // By default Dubai
  const [mapCenters, setMapCenters] = useState([
    { latitude: 25.2048, longitude: 55.2708 },
  ]);

  // By default Dubai
  const [center, setCenter] = useState({
    lat: 25.2048,
    lng: 55.2708,
  });

  useEffect(() => {
    if (mapDatas?.length) {
      setCenter({
        ...center,
        lat: mapDatas[0].latitude,
        lng: mapDatas[0].longitude,
      });
      setMapCenters(mapDatas);
    }
  }, [mapDatas]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, //please add your google map API key here
  });

  const [map, setMap] = React.useState(null);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const options = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={12}
      onUnmount={onUnmount}
      onLoad={onLoad}
      className="mapsec"
      options={options}
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={120}
        maxZoom={18}
      >
        {(clusterer) =>
          mapCenters.length >= 1 &&
          mapCenters.map((item) => (
            <>
              <Marker
                key={item?.id}
                position={{
                  lat: item.latitude,
                  lng: item.longitude,
                }}
                clusterer={clusterer}
                onClick={() => checkUsertoRedirect(item)}
              >
                {showInfo && <ShowInfoWindow key={Math.random().toString()} />}
              </Marker>
            </>
          ))
        }
      </MarkerClusterer>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(MyComponent);
