import React from "react";
import LoadingScreen from "../components/LoadingScreen";

function LoadingPage() {
  return (
    <LoadingScreen/>
  );
}

export default LoadingPage;
