import React, { useRef, useEffect, useState, useContext } from "react";
import Style from "./BillBoardsUpdate.module.scss";
import PaymentSlider from "../PaymentSlider";
//import SearchInput from "../SearchInput";
import { useLocation, useHistory } from "react-router-dom";
//import Assets from "../Layout/CommonLayout/Asset";
import BillBoards from "../BillBoards";
import Button from "@restart/ui/esm/Button";
// import setting1value from "../../../src/App"
import Skeleton from "@mui/material/Skeleton";
import { loadBillBoardById } from "../../api/userApi";
import { Context } from "../../context";
import Moment from "react-moment";

function BillBoardsUpdate() {
    // context state
    const {
        state: { user },
    } = useContext(Context);

    // Get the search Keyword from the Url
    const searchQuery = useLocation().search;
    const boardID = new URLSearchParams(searchQuery).get("id");

    const [isScrollUp, setIsScrollUp] = useState(false);

    const [events, setEvents] = useState(60);
    const [downloads, setDownloads] = useState(20);
    const [perday, setPerday] = useState(40);
    const history = useHistory();
    const [boardsList, setBoardsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flowType, setFlowType] = useState("");

    useEffect(() => {
        setFlowType(localStorage.getItem("flow"));
    }, []);

    useEffect(() => {
        if (boardID && user !== null) {
            loadBillBoardById(
                setBoardsList,
                setLoading,
                boardID,
                user?.email,
                user?.token,
                user?.tokenType,
                user?.client,
                user?.exp
            );
        }
    }, [boardID, user]);

    const scrollRef = useRef();

    const scroll = (scrollOffset) => {
        // If scroll down , it shows up arrow
        if (scrollOffset === -133) setIsScrollUp(true);
        scrollRef.current.scrollTop -= scrollOffset;
        // If it goes fully up, it hides up arrow
        if (scrollRef.current.scrollTop === 0) setIsScrollUp(false);
    };

    const openEventDescription = (event) => {
        history.push(
            {
                pathname: "/billBoardDescription",
                search: `?name=${event?.id || ""}&date=${
                    event?.created_at || ""
                }`,
            },
            {
                image_url: event?.image_url,
            }
        );
    };

    const showLoading = () => (
        <>
            <div className={Style.board_result_item}>
                <div className={Style.board_result_item_inner}>
                    <div className={Style.image_wrapper}>
                        <div className={Style.image_container}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <div className={Style.content_wrapper}>
                        <h5>
                            <Skeleton variant="text" />
                        </h5>
                        <p>
                            <Skeleton variant="text" />
                        </p>
                    </div>
                </div>
            </div>

            <div className={Style.board_result_item}>
                <div className={Style.board_result_item_inner}>
                    <div className={Style.image_wrapper}>
                        <div className={Style.image_container}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <div className={Style.content_wrapper}>
                        <h5>
                            <Skeleton variant="text" />
                        </h5>
                        <p>
                            <Skeleton variant="text" />
                        </p>
                    </div>
                </div>
            </div>
            <div className={Style.board_result_item}>
                <div className={Style.board_result_item_inner}>
                    <div className={Style.image_wrapper}>
                        <div className={Style.image_container}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <div className={Style.content_wrapper}>
                        <h5>
                            <Skeleton variant="text" />
                        </h5>
                        <p>
                            <Skeleton variant="text" />
                        </p>
                    </div>
                </div>
            </div>
            <div className={Style.board_result_item}>
                <div className={Style.board_result_item_inner}>
                    <div className={Style.image_wrapper}>
                        <div className={Style.image_container}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <div className={Style.content_wrapper}>
                        <h5>
                            <Skeleton variant="text" />
                        </h5>
                        <p>
                            <Skeleton variant="text" />
                        </p>
                    </div>
                </div>
            </div>
            <div className={Style.board_result_item}>
                <div className={Style.board_result_item_inner}>
                    <div className={Style.image_wrapper}>
                        <div className={Style.image_container}>
                            <Skeleton
                                variant="rectangular"
                                width={210}
                                height={68}
                            />
                        </div>
                    </div>
                    <div className={Style.content_wrapper}>
                        <h5>
                            <Skeleton variant="text" />
                        </h5>
                        <p>
                            <Skeleton variant="text" />
                        </p>
                    </div>
                </div>
            </div>
        </>
    );

    const onScrollBoard = (e) => {
        if (e.target.scrollTop) {
            setIsScrollUp(true);
        } else {
            setIsScrollUp(false);
        }
    };

    return (
        <BillBoards>
            <div
                className={Style.billboard_inner}
                id="search__bill__scroll"
                onScroll={onScrollBoard}
                ref={scrollRef}
            >
                <div className={Style.billboard_main}>
                    <div className={Style.billboard_head}>
                        <div className={Style.back_btn}>
                            <Button
                                bsPrefix="custom"
                                onClick={() =>
                                    history.push("/billboardssearch")
                                }
                            ></Button>
                        </div>
                        <h4>BillBoards</h4>
                    </div>

                    <PaymentSlider />

                    <div className={Style.result_wrapper_billboard}>
                        <div className={Style.result_inner}>
                            <div className={Style.result_item}>
                                <div className={Style.result_item_inner}>
                                    <span className={Style.result_count}>
                                        {events}
                                    </span>
                                    <p className={Style.result_text}>
                                        Number of Events
                                    </p>
                                </div>
                            </div>

                            <div className={Style.result_item}>
                                <div className={Style.result_item_inner}>
                                    <span className={Style.result_count}>
                                        {downloads}
                                    </span>
                                    <p className={Style.result_text}>
                                        Number of Downloads
                                    </p>
                                </div>
                            </div>

                            <div className={Style.result_item}>
                                <div className={Style.result_item_inner}>
                                    <span className={Style.result_count}>
                                        {perday}
                                    </span>
                                    <p className={Style.result_text}>
                                        Events Per Day
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={Style.board_billboard}>
                        {!loading && boardsList.length <= 0 && (
                            <div className={Style.no__results}>
                                <h3>No Results Found!</h3>
                            </div>
                        )}
                        <div className={Style.board_result_inner}>
                            {loading && showLoading()}

                            {!loading &&
                                boardsList.length >= 1 &&
                                boardsList.map((billBoard) => {
                                    return (
                                        <div
                                            key={billBoard.id}
                                            className={Style.board_result_item}
                                        >
                                            <div
                                                onClick={() =>
                                                    openEventDescription(
                                                        billBoard
                                                    )
                                                }
                                                className={
                                                    Style.board_result_item_inner
                                                }
                                            >
                                                <div
                                                    className={
                                                        Style.image_wrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            Style.image_container
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                billBoard.image_url
                                                            }
                                                            alt=""
                                                            onClick={() =>
                                                                openEventDescription(
                                                                    billBoard
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        Style.content_wrapper
                                                    }
                                                >
                                                    {/*  <h5>{billBoard.name}</h5> */}
                                                    <h5>{billBoard.id}</h5>
                                                    <p>
                                                        <Moment format="DD/MM/YYYY hh:mm A">
                                                            {
                                                                billBoard.created_at
                                                            }
                                                        </Moment>
                                                    </p>
                                                </div>
                                                {flowType ===
                                                "searchdesktop" ? (
                                                    <div
                                                        className={
                                                            Style.map_wrapper
                                                        }
                                                    >
                                                        {/* <span className="icon-map"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span> */}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className={Style.read_more_wrapper}>
                        {boardsList.length >= 1 && (
                            <>
                                <button
                                    className={Style.read_more_btn}
                                    onClick={() => scroll(-133)}
                                ></button>
                                {isScrollUp && (
                                    <button
                                        className="icon__rotate"
                                        onClick={() => scroll(133)}
                                    ></button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </BillBoards>
    );
}

export default BillBoardsUpdate;
