import React from "react";
import UserSignup from "../components/UserSignup";


function UserSignupPage() {
  return (
    <UserSignup/>
  );
}

export default UserSignupPage;
