import React from "react";
import ConfigureJob from "../components/ConfigureJob";
import Header from "../components/Header";


function ConfigureJobPage() {
  return (
    <>
    <Header/>
    <ConfigureJob/>
    </>
  );
}

export default ConfigureJobPage;
