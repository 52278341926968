import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../context";
import { Button } from "react-bootstrap";
import Style from "./UserLogin.module.scss";
import { Link, useHistory, Redirect } from "react-router-dom";
//import CommonLayout from "../Layout/CommonLayout";
import Assets from "../Layout/CommonLayout/Asset";
import { toast } from "react-toastify";
import { axiosInstance } from "../../axios";
import Alert from "react-bootstrap/Alert";
import { LoginSignupContext } from "../../context/loginSignupModal";

function UserLogin() {
  const history = useHistory();

  // context state
  const { dispatch } = useContext(Context);

  const {
    loginSignupState: { isLogin },
    dispatchLoginSignup,
  } = useContext(LoginSignupContext);

  const [values, setValues] = useState({
    email: "",
    password: "",
    btnloading: false,
  });

  const { email, password, btnloading } = values;
  const [redirectToUser, setRedirectToUser] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(true);
  // For checking back button
  const [backButton, setBackButton] = useState(true);

  // Error Values
  const [errorValues, setErrorValues] = useState({
    showError: false,
    error: "",
  });

  const { showError, error } = errorValues;

  useEffect(() => {
    // For checking the user is already Login or not
    // if login redirect to homepage
    const checkToken = JSON.parse(localStorage.getItem("token"));
    if (checkToken && checkToken?.email) {
      setRedirectToUser(true);
    }
  }, []);

  // For changing login values
  const handleChange = (name) => (event) => {
    if (name === "acceptTerms") {
      setAcceptTerms(!acceptTerms);
    } else {
      setValues({
        ...values,
        [name]: event.target.value,
        btnloading: false,
      });
    }

    if (showError) {
      setErrorValues({ ...errorValues, showError: false, error: "" });
    }
  };

  // For Login Function
  const clickSubmit = async (e) => {
    e.preventDefault();
    if (!acceptTerms) {
      //toast.error("Please accept the terms and conditions to continue.");
      setErrorValues({
        ...errorValues,
        showError: true,
        error: "Please accept the terms and conditions to continue.",
      });
      return;
    }
    try {
      setValues({ ...values, btnloading: true });
      const results = await axiosInstance.post("/api/auth/sign_in", {
        email,
        password,
      });

      const { headers, data } = results;

      if (data?.data) {
        const tokenData = {
          id: data?.data?.id,
          email: data?.data?.email,
          name: data?.data?.name,
          token: headers["access-token"],
          tokenType: headers["token-type"],
          client: headers["client"],
          exp: headers["expiry"],
        };
        localStorage.setItem("token", JSON.stringify(tokenData));
        dispatch({
          type: "LOGIN",
          payload: tokenData,
        });
        toast.success("Your account has been successfully Login.");
        setValues({
          ...values,
          email: "",
          password: "",
          btnloading: false,
        });
        setAcceptTerms(false);
        if (backButton) setRedirectToUser(true);
      } else {
        setValues({ ...values, btnloading: false });
        //toast.error("Something went wrong, Please try again.");
        setErrorValues({
          ...errorValues,
          showError: true,
          error: "Something went wrong, Please try again.",
        });
      }
      dispatchLoginSignup({ type: "CLOSE_LOGIN_SIGNUP" });
    } catch (error) {
      setValues({ ...values, btnloading: false });
      setErrorValues({
        ...errorValues,
        showError: true,
        error:
          error?.response?.data?.errors[0] ||
          "Something went wrong, Please try again.",
      });
      //toast.error(error?.response?.data?.errors[0] || "Something went wrong, Please try again.");
    }
  };

  const checkRedirectToReferrer = () => {
    if (redirectToUser) return <Redirect to="/billboardssearch" />;
  };

  const goToPreviousPath = () => {
    sessionStorage.setItem("dis_anim", true);
    const backData = JSON.parse(sessionStorage.getItem("backMap"));
    if (backData) {
      sessionStorage.removeItem("backMap");
      return history.push(backData);
    }
    return history.push("/map");
  };

  useEffect(() => {
    setBackButton(JSON.parse(localStorage.getItem("loginModal")));
  }, []);

  return (
    <>
      {/*  <CommonLayout> */}

      <div className={Style.login_wrapper}>
        <div className={Style.login_inner}>
          {backButton && (
            <div className={Style.back_to_page}>
              <Button
                bsPrefix="custom"
                onClick={() => goToPreviousPath()}
              ></Button>
            </div>
          )}
          <div className={Style.logo_wrapper_inner}>
            <div className={Style.container}>
              <img
                src={Assets.logoMain}
                alt=""
                onClick={() => history.push("/")}
              />
            </div>
          </div>

          {showError && (
            <Alert
              variant="danger"
              onClose={() =>
                setErrorValues({
                  ...errorValues,
                  showError: false,
                  error: "",
                })
              }
              dismissible
            >
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>{error}</p>
            </Alert>
          )}

          <div className={Style.form_wrapper}>
            <form onSubmit={clickSubmit} className={Style.form_wrapper_inner}>
              <div className={Style.from_group}>
                <label htmlFor="">Email ID</label>
                <input
                  type="email"
                  onChange={handleChange("email")}
                  value={email}
                  required
                />
              </div>

              <div className={Style.from_group}>
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  onChange={handleChange("password")}
                  value={password}
                  required
                />
              </div>

              {/* <div className={Style.from_group}>
                <input
                  type="checkbox"
                  checked={acceptTerms}
                  onChange={handleChange("acceptTerms")}
                  id="checkbox1"
                />
                <label htmlFor="checkbox1">
                  <span className={Style.check}></span>I Accept&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://nayan.co/#/policies/privacy"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div> */}

              <div className={Style.from_group}>
                <Button
                  disabled={btnloading}
                  bsPrefix="custom"
                  type="submit"
                  className={Style.login_btn}
                >
                  {btnloading ? "Loading..." : "Log In"}
                </Button>
              </div>

              {/* <div className={Style.social_media_login}>
                                <Button
                                    bsPrefix="custom"
                                    className={Style.facebook}
                                >
                                    <img src={Assets.facebook} alt="" />
                                    <span>Facebook</span>
                                </Button>
                                <Button
                                    bsPrefix="custom"
                                    className={Style.google}
                                >
                                    <img src={Assets.google} alt="" />
                                    <span>Google</span>
                                </Button>
                            </div> */}

              <div className={Style.action_wrapper}>
                <p>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </p>
              </div>

              <div className={Style.action_wrapper}>
                <p>
                  New member? <Link to="/userSignup">Sign Up</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      {checkRedirectToReferrer()}
      {/*  </CommonLayout> */}
    </>
  );
}

export default UserLogin;
