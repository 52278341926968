import React from "react";
import Header from "../components/Header";
import DirectionMap from "../components/DirectionMap";
import RouteMap from "../components/Map/routemap";
import Directions from "../components/Map/directionsmap";

function DirectionMapPage() {
  return (
    <>
      <Header />
      <DirectionMap />
      {/* <RouteMap/> */}
      {/* <Directions/> */}
    </>
  );
}

export default DirectionMapPage;
