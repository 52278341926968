import React, { useState } from "react";
import Style from "./Footer.module.scss";

function Footer() {
  const [leftLinks, setLeftLinks] = useState([
    { name: "About Us", link: "#/" },
    { name: "Our Cases", link: "#/" },
    { name: "Market Place", link: "#/" },
  ]);

  /* const [rightLinks, setRightLinks] = useState([
        { name: "Privacy", link: "https://nayan.co/#/policies/privacy" },
        { name: "Terms", link: "https://nayan.co/#/policies/privacy" },
        { name: "Setting", link: "#/" },
    ]); */

  return (
    <footer
      data-mobile-hide="true"
      className={`${Style.footer_main} ${Style.active}`}
    >
      <div className={Style.footer_wrapper}>
        <ul className={Style.footer_links}>
          {leftLinks.map((link, i) => {
            return (
              <li key={i}>
                <a href={link?.link}>{link?.name}</a>
              </li>
            );
          })}
        </ul>

        <ul className={Style.footer_links}>
          {/*  {rightLinks.map((link, i) => {
                        return (
                            <li key={i}>
                                <a href={link?.link}>{link?.name}</a>
                            </li>
                        );
                    })} */}
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://nayan.co/#/policies/privacy"
            >
              Privacy
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://nayan.co/#/policies/privacy"
            >
              Terms
            </a>
          </li>
          <li>
            <a href="#/">Setting</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
