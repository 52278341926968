import React from "react";
import LoadingMap from "../components/LoadingMap";

function LoadingMapPage() {
  return (
    <LoadingMap/>
  );
}

export default LoadingMapPage;
