import React from "react";
import Style from "./MarketHeader.module.scss";
import Assets from "../Layout/CommonLayout/Asset";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function MarketHeader() {
 
    return (
        <section className={Style.siteheader}>
        <div className={Style.container}>
            <div className={Style.topheader}>
                <ul className={Style.smedia}>
                    <li><a href="http://" target="_blank"><img src={Assets.facebook} alt=""/></a></li>
                    <li><a href="http://" target="_blank"><img src={Assets.tweet} alt=""/></a></li>
                    <li><a href="http://" target="_blank"><img src={Assets.instagram} alt=""/></a></li>
                </ul>
            </div>
        </div>
        
        <div className={Style.mainheader}>
            <div className={Style.container}>
                <div className={Style.headerwrap}>
                    <div className={Style.logosec}>
                        <Link to="/"><img src={Assets.main_logo} alt="Nayanz"/></Link>
                    </div>
                    <div className={Style.rightsec}>

                        <Nav
                        className={Style.mainav}
                        >
                            <ul className={Style.mainmenu}>
                                <li>
                                    <Link to="/aboutus">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/marketplace">Market Place</Link>
                                </li>
                                <li>
                                    <Link to="/usecases">Use cases</Link>
                                </li>
                                <li>
                                    <Link to="/">Contact</Link>
                                </li>
                            </ul>
                            
                            <div className={Style.seacrh_admin}>
                                <div className={Style.searchbar}>
                                    <form action="">
                                        <div className={Style.input_holder}>
                                            <input type="text" name="" id="" className={Style.form_control}/>
                                            <button type="submit" className={Style.searchicon}><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"><path d="M8,16A8,8,0,0,1,2.344,2.344,8,8,0,1,1,13.657,13.657,7.947,7.947,0,0,1,8,16ZM8,1.526A6.474,6.474,0,1,0,14.474,8,6.481,6.481,0,0,0,8,1.526Z" /><path d="M6.761,5.606,1.394.239A.816.816,0,1,0,.239,1.394L5.606,6.761A.816.816,0,0,0,6.761,5.606Z" transform="translate(12 12)"/></svg></button>
                                        </div>
                                    </form>
                                </div>
                                <div className={Style.admin_login}>
                                    <div className={Style.wrapper}>
                                        <div className={Style.hamb}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                        <div className={Style.avatar}><img src={Assets.log} alt=""/></div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={Style.close_icon} 
                            // onclick="hamburger()"
                            >
                                <span></span>
                                <span></span>
                            </div>
                        </Nav>
                        <div className={Style.hamburger_icon}
                        //  onclick="hamburger()"
                         >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default MarketHeader;