import React, { useState } from "react";
import Style from "./AboutUs.module.scss";
import Assets from "../Layout/CommonLayout/Asset";
import ReactPlayer from "react-player";

function AboutUs() {
    const [services, setServices] = useState([
        { image: Assets.damage_road, name: "Damage Road" },
        { image: Assets.injured_animals, name: "Injured Animals" },
        { image: Assets.damage_buildings, name: "Damage Buildings" },
        { image: Assets.traffic_light, name: "Traffic Light Violation" },
        { image: Assets.mango_tree, name: "Mango Tree" },
    ]);
    const [useCases, setUseCases] = useState([
        {
            heading: "Usecase Heading",
            description:
                "Morbi ornare velit vitae felis commodo, at blandit est vestibulum. Mauris quam arcu, ",
        },
        {
            heading: "Usecase Heading",
            description:
                "Morbi ornare velit vitae felis commodo, at blandit est vestibulum. Mauris quam arcu, ",
        },
    ]);
    const [volume, setVolume] = useState(100);
    const [play, setPlay] = useState(false);

    return (
        <div className={Style.whiteBg}>
            <section className="slimbnr">
                <div className="container">
                    <header className="pagetitle">
                        <h1>About Us</h1>
                    </header>
                </div>
            </section>

            <section className={Style.abtintro}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className={Style.imgsec}>
                                <figure className={Style.imgwrap}>
                                    <img src={Assets.about_01} alt="" />
                                </figure>
                                <figure className={Style.imgwrap}>
                                    <img src={Assets.about_02} alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className={Style.cntsec}>
                                <h2 className={Style.sectitle}>Big Heading</h2>
                                <div className={Style.cnt}>
                                    <p>
                                        Morbi ornare velit vitae felis commodo,
                                        at blandit est vestibulum. Mauris quam
                                        arcu, facilisis ut vestibulum sit met,
                                        pellentesque in metus. Aenean pharetra
                                        mauris ac sem faucibus, consectetur
                                        ullamcorper ex fermentum. Mauris
                                        placerat scelerisque pulvinar.
                                    </p>
                                </div>
                                <h3>Sub Heading One</h3>
                                <p>Morbi ornare velit vitae felis </p>
                                <h3>Sub Heading Two</h3>
                                <p>Morbi ornare velit vitae felis</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={Style.servicesec}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <h2 className={Style.sectitle}>Big Heading Two</h2>
                        </div>
                        <div className="col-lg-8">
                            <div className={Style.cntsec}>
                                <p>
                                    Morbi ornare velit vitae felis commodo, at
                                    blandit est vestibulum. Mauris quam arcu,
                                    facilisis ut vestibulum sit amet,
                                    pellentesque in metus. Aenean pharetra
                                    mauris ac sem faucibus, consectetur
                                    ullamcorper ex fermentum. Mauris placerat
                                    scelerisque pulvinar.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={Style.services}>
                        {services.map((service) => {
                            return (
                                <div className={Style.items}>
                                    <figure className={Style.iconsec}>
                                        <img src={service.image} alt="" />
                                    </figure>
                                    <header className={Style.itemtitle}>
                                        {service.name}
                                    </header>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <section className={Style.endsec}>
                <div className="container">
                    <div className={Style.endsecrow}>
                        <div className={Style.videocol}>
                            <div className={Style.videosec}>
                                <ReactPlayer
                                    url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                                    volume={volume}
                                    width={"100%"}
                                    playing={true}
                                    height={"100%"}
                                    controls={true}
                                    pip={true}
                                    light={Assets.videoposter}
                                    playIcon={
                                        <div className={Style.videoposter}>
                                            <button
                                                className={Style.paybtn}
                                            ></button>
                                        </div>
                                    }
                                    className={Style.video}
                                />
                                {/* <video className="video">
                                    <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                                </video> */}
                                {/* <figure className={Style.videoposter}><img src={Assets.videoposter} alt=""/> <button className={Style.paybtn}></button></figure> */}
                            </div>
                        </div>
                        <div className={Style.boxscol}>
                            {useCases.map((usecase) => {
                                return (
                                    <div className={Style.box}>
                                        <div className={Style.wrapper}>
                                            <header className={Style.boxtitle}>
                                                <a href="">{usecase.heading}</a>
                                            </header>
                                            <div className={Style.cntsec}>
                                                <p>{usecase.description} </p>
                                            </div>
                                            <a
                                                href="http://"
                                                className={Style.arwlink}
                                            ></a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default AboutUs;
