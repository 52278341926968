import React, { useState } from "react";
import Style from "./MarketFooter.module.scss";
import Assets from "../Layout/CommonLayout/Asset";

function MarketFooter() {
  const [socialMedias, setSocialMedias] = useState([
    { image: Assets.fb_white },
    { image: Assets.tweet_white },
    { image: Assets.insta_white },
  ]);
  const [links, setLinks] = useState([
    { name: "About Us" },
    { name: "Our Pricing" },
    { name: "Services" },
    { name: "Blog" },
    { name: "Contact" },
  ]);
  const [services, setServices] = useState([
    { name: "Proin laoreet mollis convallis" },
    { name: "Proin laoreet mollis convallis" },
    { name: "Proin laoreet mollis convallis" },
    { name: "Proin laoreet mollis convallis" },
    { name: "Proin laoreet mollis convallis" },
    { name: "Proin laoreet mollis convallis" },
  ]);
  const [rights, setRights] = useState([
    { name: "Site Map" },
    { name: "Privacy Policy" },
    { name: "Terms & Conditions" },
  ]);

  return (
    <footer data-mobile-hide="true" className={Style.sitefooter}>
      <div className="container">
        <div className={Style.custom_container}>
          <div className="row">
            <div className={Style.ftrcol}>
              <div className={Style.wrap}>
                <header>
                  <img src={Assets.logo_white} alt="" />
                </header>
                <p>
                  Proin laoreet mollis convallis. Duis maximus tempor nibh id
                  luctus
                </p>
                <ul>
                  {socialMedias.map((media) => {
                    return (
                      <li>
                        <a href="" target="_blank">
                          <img src={media.image} alt="" />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={Style.ftrcol}>
              <div className={Style.wrap}>
                <header>Our Expertise</header>
                <ul>
                  {links.map((link) => {
                    return (
                      <li>
                        <a href="">{link.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={Style.ftrcol}>
              <div className={Style.wrap}>
                <header>Our Services</header>
                <ul>
                  {services.map((service) => {
                    return (
                      <li>
                        <a href="">{service.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className={Style.btmbar}>
            <div className={Style.col}>
              <p>Copyright © 2020. All rights reserved.</p>
            </div>
            <div className={Style.col}>
              <ul>
                {rights.map((item) => {
                  return (
                    <li>
                      <a href="">{item.name}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default MarketFooter;
