import React from "react";
import BillBoardsSearch from "../components/BillBoardsSearch";
import Header from "../components/Header";


function BillBoardsSearchPage() {
  return (
    <>
    <Header/>
    <BillBoardsSearch/>
    </>
  );
}

export default BillBoardsSearchPage;
