import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import Style from "./UserSignup.module.scss";
import { useHistory, Redirect } from "react-router-dom";
import Assets from "../Layout/CommonLayout/Asset";
import { register } from "../../api/commonApi";
import Alert from "react-bootstrap/Alert";
import { LoginSignupContext } from "../../context/loginSignupModal";

function UserSignup() {
  const history = useHistory();

  const {
    loginSignupState: { isLogin },
    dispatchLoginSignup,
  } = useContext(LoginSignupContext);

  const [values, setValues] = useState({
    password: "",
    password_confirmation: "",
    email: "",
    name: "",
    company_name: "",
    designation: "",
  });

  const {
    email,
    password,
    password_confirmation,
    name,
    company_name,
    designation,
  } = values;

  const [btnloading, setBtnloading] = useState(false);
  const [redirectToUser, setRedirectToUser] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  // For Handling Modal
  const userLogin = () => {
    localStorage.setItem("loginModal", true);
    history.push("/userLogin");
  };

  const [backButton, setBackButton] = useState(true);

  useEffect(() => {
    setBackButton(
      JSON.parse(localStorage.getItem("signupModal")) ||
        JSON.parse(localStorage.getItem("loginModal"))
    );
  }, []);

  // Error Values
  const [errorValues, setErrorValues] = useState({
    showError: false,
    error: "",
  });

  const { showError, error } = errorValues;

  // For changing login values
  const handleChange = (name) => (event) => {
    setBtnloading(false);

    if (name === "acceptTerms") {
      setAcceptTerms(!acceptTerms);
    } else {
      setValues({ ...values, [name]: event.target.value });
    }

    if (showError) {
      setErrorValues({ ...errorValues, showError: false, error: "" });
    }
  };

  const clickSubmit = async (e) => {
    e.preventDefault();
    if (!acceptTerms) {
      //toast.error("Please accept the terms and conditions to continue.");
      setErrorValues({
        ...errorValues,
        showError: true,
        error: "Please accept the terms and conditions to continue.",
      });
      return;
    }
    register(
      setBtnloading,
      errorValues,
      setErrorValues,
      setRedirectToUser,
      dispatchLoginSignup,
      password,
      password_confirmation,
      email,
      name,
      company_name,
      designation
    );
  };

  const checkRedirectToReferrer = () => {
    if (redirectToUser) return <Redirect to="/userLogin" />;
  };

  return (
    <>
      {/*   <CommonLayout> */}

      <div className={Style.login_wrapper}>
        <div className={Style.login_inner}>
          {backButton && (
            <div className={Style.back_to_page}>
              <Button bsPrefix="custom" onClick={userLogin}></Button>
            </div>
          )}

          <div className={Style.logo_wrapper_inner}>
            <div className={Style.container}>
              <img
                src={Assets.logoMain}
                alt=""
                onClick={() => history.push("/")}
              />
            </div>
          </div>

          {showError && (
            <Alert
              variant="danger"
              onClose={() =>
                setErrorValues({
                  ...errorValues,
                  showError: false,
                  error: "",
                })
              }
              dismissible
            >
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>{error}</p>
            </Alert>
          )}

          <div className={Style.form_wrapper}>
            <form onSubmit={clickSubmit} className={Style.form_wrapper_inner}>
              <div className="row">
                <div className="col-md-6">
                  <div className={Style.from_group}>
                    <label htmlFor="">
                      Full Name
                      <span className={Style.rq}>*</span>
                    </label>
                    <input
                      type="text"
                      onChange={handleChange("name")}
                      value={name}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className={Style.from_group}>
                    <label htmlFor="">
                      Company Name
                      <span className={Style.rq}>*</span>
                    </label>
                    <input
                      type="text"
                      onChange={handleChange("company_name")}
                      value={company_name}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className={Style.from_group}>
                    <label htmlFor="">Designation </label>
                    <input
                      type="text"
                      onChange={handleChange("designation")}
                      value={designation}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className={Style.from_group}>
                    <label htmlFor="">
                      Email ID
                      <span className={Style.rq}>*</span>
                    </label>
                    <input
                      type="email"
                      onChange={handleChange("email")}
                      value={email}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className={Style.from_group}>
                    <label htmlFor="">
                      Password
                      <span className={Style.rq}>*</span>
                    </label>
                    <input
                      type="password"
                      onChange={handleChange("password")}
                      value={password}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className={Style.from_group}>
                    <label htmlFor="">
                      Confirm Password
                      <span className={Style.rq}>*</span>
                    </label>
                    <input
                      type="password"
                      onChange={handleChange("password_confirmation")}
                      value={password_confirmation}
                    />
                  </div>
                </div>
              </div>

              <div className={Style.from_group}>
                <input
                  type="checkbox"
                  checked={acceptTerms}
                  onChange={() => setAcceptTerms(!acceptTerms)}
                  id="checkbox2"
                />
                <label htmlFor="checkbox2">
                  <span className={Style.check}></span>I Accept&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://nayan.co/#/policies/privacy"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>

              <div className={Style.from_group}>
                <Button
                  disabled={btnloading}
                  bsPrefix="custom"
                  type="submit"
                  className={
                    Style.login_btn
                  } /* onClick={()=>history.push("/userLogin")} */
                >
                  {btnloading ? "Loading..." : "Sign Up"}
                </Button>
              </div>

              {/* <div className={Style.social_media_login}>
                                <Button
                                    bsPrefix="custom"
                                    className={Style.facebook}
                                >
                                    <img src={Assets.facebook} alt="" />
                                    <span>Facebook</span>
                                </Button>
                                <Button
                                    bsPrefix="custom"
                                    className={Style.google}
                                >
                                    <img src={Assets.google} alt="" />
                                    <span>Google</span>
                                </Button>
                            </div> */}
            </form>
          </div>
        </div>
      </div>
      {checkRedirectToReferrer()}
      {/*  </CommonLayout> */}
    </>
  );
}

export default UserSignup;
