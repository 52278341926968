import React, { useState, memo } from "react";
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Style from "./AccountSettings.module.scss";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import SecurityIcon from "@mui/icons-material/Security";
import Logout from "@mui/icons-material/Logout";
import { Security, SecurityRounded } from "@mui/icons-material";

function AccountSettings({ logout, userName }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  const goToUrl = (url) => {
    localStorage.setItem("loginModal", true);
    sessionStorage.setItem(
      "backMap",
      JSON.stringify(history?.location?.pathname + history?.location?.search)
    );
    return history.push(url);
  };

  const securityIcon = (
    <Link
      style={{ color: "grey" }}
      to={{ pathname: "https://traffic.nayan.co/" }}
      target="_blank"
    >
      <MenuItem style={{ padding: "8px 14px" }}>
        <Security fontSize="medium" />{" "}
        <span style={{ marginLeft: "5px", color: "black" }}>Traffic</span>
      </MenuItem>
    </Link>
  );

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
          <Avatar />
          <div className={Style.profile__dots}></div>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {userName
          ? [
              <Link style={{ color: "black" }} to="/billboardssearch">
                <MenuItem>
                  <Avatar /> {userName || ""}
                </MenuItem>
              </Link>,
              <Divider />,
              securityIcon,
              <Divider />,
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>,
            ]
          : [
              <MenuItem onClick={() => goToUrl("/userLogin")}>Login</MenuItem>,

              <Divider />,
              securityIcon,
              <Divider />,

              <MenuItem onClick={() => goToUrl("/userSignup")}>
                Sign Up
              </MenuItem>,
            ]}
      </Menu>
    </>
  );
}

export default memo(AccountSettings);
